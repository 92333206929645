// import axios from 'axios';
import data from './data';

// const gtg = process.env.REACT_APP_BASE_URL;
const intialState = [...data];
// const API = `https://${gtg}/packages`;
// const API = 'http://localhost:3000/packages';

const GET_PACKAGES = 'GET_PACKAGES';

const fetchPackages = () => (dipatch) => {
  // axios.get(API).then((res) => {
  // let packages = [];
  const packages = intialState;
  dipatch({
    type: GET_PACKAGES,
    packages,
  });
  // });
};

// eslint-disable-next-line default-param-last
const packageReducer = (state = intialState, action) => {
  switch (action.type) {
    case GET_PACKAGES:
      return [
        ...action.packages,
      ];
    default:
      return state;
  }
};

export default packageReducer;
export { fetchPackages };
